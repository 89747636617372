import * as sceneApi from 'api/scene';
import { Scene } from 'types/scene';

export const ACTION_TYPES = {
    setSceneInfo: 'setSceneInfo',
} as const;

export const createSetSceneAction = (scene: Scene) => ({
    type: ACTION_TYPES.setSceneInfo,
    payload: scene,
});

export const getSceneInfo = (sceneId: string) => async (dispatch) => {
    const sceneInfo = await sceneApi.getSceneById(sceneId);
    dispatch(createSetSceneAction(sceneInfo));
    return sceneInfo;
};

export const updateSceneInfo = (sceneInfo: Partial<Scene>) => async (dispatch) => {
    const updatedScene = await sceneApi.updateScene(sceneInfo);
    dispatch(createSetSceneAction(updatedScene));
    return updatedScene;
};
