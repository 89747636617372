import { Permission } from 'types/permission';

export const ACTION_TYPES = {
    setPermission: 'setPermission',
} as const;

export const setPermission = (permission: Permission) => ({
    type: ACTION_TYPES.setPermission,
    payload: permission,
});
