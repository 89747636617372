import React, { Suspense } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Routes from './routes';
import './App.css';
import 'i18n';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { getDealerInfo, setDealerAsIstaging } from 'actions/dealer';
import { skipTutorial } from 'actions/tutorial';
import ThemeProvider from 'ThemeProvider';
import { useTranslation } from 'react-i18next';
import { autoLogin } from 'actions/auth';

const App: React.FC = () => {
    const { title, favicon, code } = useAppSelector((store) => store.dealer);
    const auth = useAppSelector((store) => store.auth);
    const [isAuthorizing, setIsAuthorizing] = React.useState(true);
    const userDealerCode = auth?.dealerCode || new URLSearchParams(window.location.search).get('dealer') || '';
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(autoLogin())
            .catch(console.info)
            .finally(() => setIsAuthorizing(false));
    }, []);

    React.useEffect(() => {
        if (userDealerCode) {
            dispatch(getDealerInfo(userDealerCode));
            dispatch(skipTutorial());
        } else {
            dispatch(setDealerAsIstaging());
        }
    }, [userDealerCode]);

    React.useEffect(() => {
        document.title = title;
        document.querySelector("link[rel*='icon']").setAttribute('href', favicon);
    }, [title, favicon]);

    return (
        <ThemeProvider>
            <Suspense fallback={<div />}>
                <Routes isAuthorizing={isAuthorizing} />
            </Suspense>
        </ThemeProvider>
    );
};

export default App;
