import { platformApi, getErrorMsg } from 'api';
import { IBanner } from 'types/banner';

export const getBanners = async () => {
    try {
        const urlEnd = '/public/v1/activityBanner/MM';
        const response = await platformApi.get<IBanner[]>(urlEnd);
        return response.data;
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};
