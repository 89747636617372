import { AnyAction } from 'redux';

import { ACTION_TYPES } from 'actions/scenes';
import { Scene } from 'types/scene';
import { withDefault, getSceneModel } from 'api/models';

interface Action extends AnyAction {
    type: keyof typeof ACTION_TYPES;
}

const initialState = withDefault(getSceneModel)();

const scenesReducer = (states: Scene[] = [initialState], action: Action): Scene[] => {
    switch (action.type) {
        case ACTION_TYPES.getScenes:
            return action.payload;
        default:
            return states;
    }
};

export default scenesReducer;
