import { AnyAction } from 'redux';

import { ACTION_TYPES } from 'actions/auth';
import { UserProfile } from 'types/auth';

interface Action extends AnyAction {
    type: keyof typeof ACTION_TYPES;
}

const initialState = null;

const authReducer = (state: UserProfile = initialState, action: Action): UserProfile => {
    switch (action.type) {
        case ACTION_TYPES.setUser:
            if (action.payload == null) return null;
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default authReducer;
