import React, { useEffect, useState } from 'react';
import clx from 'classnames';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button, List, ListItem, ListItemText, ListItemIcon, Collapse, Chip, Typography, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Drawer from '@material-ui/core/Drawer';
import TutorialNextStep from 'components/Tutorial/TutorialNextStep';
import MySceneIcon from '@material-ui/icons/Whatshot';
import MaterialIcon from '@material-ui/icons/Star';
import TemplateIcon from '@material-ui/icons/ViewCompact';
import { ReactComponent as StarIcon } from 'images/icon/star.svg';
import { TutorialStep } from 'components/Tutorial/TutorialStepContainer';
import { useAppSelector, useAppDispatch } from 'hooks/store';
import { Category, getTemplateCategories } from 'api/categories';
import { getFeaturesMap } from 'store';

const useStyles = makeStyles((theme) => ({
    createSceneBtn: {
        padding: '23px 30px',
        marginBottom: 60,
        textTransform: 'initial',
        // color: 'inherit',
        borderRadius: 40,
        // background: `transparent linear-gradient(90deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%) 0% 0% no-repeat padding-box`,
    },
    listItemText: {
        paddingLeft: 14,
        fontWeight: 500,
        lineHeight: '38px',
    },
    nestedListItemText: {
        paddingLeft: 40,
        lineHeight: '30px',
    },
}));

enum MenuTabs {
    MY_SHOWROOM,
    MATERIAL,
    TEMPLATE,
}

const CreateSceneButton = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();
    const tutorial = useAppSelector((store) => store.tutorial);

    return (
        <Button
            className={classes.createSceneBtn}
            color="primary"
            variant="contained"
            onClick={() => navigate('/my-template')}
        >
            <AddIcon fontSize="large" />
            <Typography variant="h5">{t('scene.createNewScene')}</Typography>
            {tutorial.currentStep === TutorialStep.STEP3 && (
                <TutorialNextStep
                    title={t('scene.createNewScene')}
                    description={t('tutorial.creatSceneDescription')}
                    skip={t('tutorial.skip')}
                    prev={t('tutorial.back')}
                    nextStep={t('tutorial.next')}
                    nextPath="/"
                    currentStep={tutorial.currentStep}
                />
            )}
        </Button>
    );
};

const SideMenu = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { pathname } = useLocation();
    const [currentTab, setCurrentTab] = useState(MenuTabs.MY_SHOWROOM);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [categoryList, setCategoryList] = useState<Category[]>();
    const tutorial = useAppSelector((store) => store.tutorial);
    const { createScene, template, ms } = useAppSelector(getFeaturesMap);
    const dispatch = useAppDispatch();
    const go = (route = '') => {
        navigate(route);
    };

    const MenuTabName = [
        {
            path: '/',
            name: t('scene.myScenes'),
            icon: MySceneIcon,
        },
        {
            path: '/material-manage',
            name: t('avatar.materialManagement'),
            icon: MaterialIcon,
        },
        {
            path: ms ? '/template/templates' : '/template/my-template',
            name: t('template.subMenuTitle'),
            icon: TemplateIcon,
            hidden: !template && !ms,
        },
    ];

    const handleChangeTab = (path: string) => () => {
        if (path === '/template') setDropdownOpen(!dropdownOpen);
        go(path);
    };

    const renderTutorial = (path: string) => {
        if (tutorial.currentStep === TutorialStep.STEP2 && path === '/material-manage') {
            return (
                <TutorialNextStep
                    title={t('avatar.materialManagement')}
                    description={t('tutorial.materialDescription')}
                    skip={t('tutorial.skip')}
                    prev={t('tutorial.back')}
                    nextStep={t('tutorial.next')}
                    nextPath="/"
                    currentStep={tutorial.currentStep}
                />
            );
        }

        if (tutorial.currentStep === TutorialStep.STEP4 && path === '/') {
            return (
                <TutorialNextStep
                    title={t('scene.myScenes')}
                    description={t('tutorial.mySceneDescription')}
                    skip={t('tutorial.skip')}
                    prev={t('tutorial.back')}
                    nextStep={t('tutorial.next')}
                    nextPath="/"
                    currentStep={tutorial.currentStep}
                />
            );
        }

        return null;
    };
    useEffect(() => {
        if (pathname === '/template') setDropdownOpen(true);
        getTemplateCategories().then((res) => {
            const sceneCategories = res.filter((category) => category.type === 'scene');
            setCategoryList(sceneCategories);
        });
    }, [pathname]);

    return (
        <Box px={2} minWidth={280}>
            {createScene && <CreateSceneButton />}
            <List>
                {MenuTabName.filter((tab) => !tab.hidden).map((tab, index) => (
                    <ListItem
                        button
                        key={tab.path}
                        selected={pathname === tab.path}
                        onClick={handleChangeTab(tab.path)}
                    >
                        <tab.icon></tab.icon>
                        <Typography className={classes.listItemText}>{tab.name}</Typography>
                        {tab.path && renderTutorial(tab.path)}
                    </ListItem>
                ))}
                {/* todo!! fix */}
                {/* <Collapse in={dropdownOpen} timeout="auto" unmountOnExit> */}
                <List component="div" disablePadding>
                    {template && (
                        <ListItem
                            button
                            selected={pathname === '/template/my-template'}
                            onClick={handleChangeTab('template/my-template')}
                        >
                            <Typography variant="body2" className={classes.nestedListItemText}>
                                {t('avatar.myTemplate')}
                            </Typography>
                        </ListItem>
                    )}
                    {ms &&
                        categoryList?.map((list) => (
                            <ListItem
                                button
                                key={list.id}
                                selected={pathname === `/template/my-template/${list.id}`}
                                onClick={handleChangeTab(`/template/my-template/${list.id}`)}
                            >
                                <Typography variant="body2" className={classes.nestedListItemText}>
                                    {t(`category.${list.name}`)}
                                </Typography>
                            </ListItem>
                        ))}
                </List>
                {/* </Collapse> */}
            </List>
        </Box>
    );
};

export default SideMenu;
