import * as creditCardApi from 'api/creditCard';
import { Payment } from 'types/creditCard';

export const ACTION_TYPES = {
    getPayment: 'getPayment',
} as const;

export const createGetPaymentAction = (payment: Payment) => ({
    type: ACTION_TYPES.getPayment,
    payload: payment,
});

export const getPayment = () => async (dispatch) => {
    const payment = await creditCardApi.getPayment();
    dispatch(createGetPaymentAction(payment));
    return payment;
};
