import React from 'react';
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IBanner } from 'types/banner';
import { getBanners } from 'api/banner';

const Banner = () => {
    const { t, i18n } = useTranslation();
    const [show, setShow] = React.useState(true);
    const [banner, setBanner] = React.useState<IBanner>();

    React.useEffect(() => {
        getBanners().then((banners) => {
            let currentLanguageBanner = banners.find((banner) => banner.language === i18n.language);
            if (!currentLanguageBanner) currentLanguageBanner = banners.find((banner) => banner.language === 'en');
            setBanner(currentLanguageBanner);
        });
    }, [i18n.language]);

    if (!show || !banner) return null;

    return (
        <>
            <div className={styles['banner-wrapper']}>
                <a href={banner.activityUrl} target="_blank" rel="noreferrer noopenner">
                    <img className={styles['banner-image']} src={banner.bannerUrl} alt="banner" />
                </a>
                <button className={styles['close-button']} onClick={() => setShow(false)}>
                    <HighlightOffIcon />
                </button>
            </div>
        </>
    );
};

export default Banner;
