import { AnyAction } from 'redux';
import { ACTION_TYPES } from 'actions/tutorial';

interface Action extends AnyAction {
    type: keyof typeof ACTION_TYPES;
}

const initialState = {
    currentStep: 1,
};

const tutorialReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case ACTION_TYPES.nextStep:
            return { ...state, currentStep: action.payload };

        default:
            return state;
    }
};

export default tutorialReducer;
