import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { useAppSelector } from 'hooks/store';

declare module '@material-ui/core/styles/createPalette' {
    export interface SimplePaletteColorOptions {
        superLight?: string;
        purple?: string;
        pink?: string;
        mainDarker?: string;
        gray?: string;
        lightGray?: string;
    }
    export interface PaletteColor {
        superLight?: string;
        purple?: string;
        pink?: string;
        mainDarker?: string;
        gray?: string;
        lightGray?: string;
    }
}

export default function (props) {
    const dealerInfo = useAppSelector((store) => store.dealer);
    const theme = createTheme({
        palette: {
            type: dealerInfo.colorMode || 'light',
            primary: {
                main: dealerInfo.mainColor || '#e91e63',
            },
            secondary: {
                main: dealerInfo.subColor || '#ce93d8',
            },
            error: {
                main: '#D96666',
            },
        },
    });
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
