import jsCookie from 'js-cookie';
import api, { setHeader, setPlatformHeader, removeHeader, removePlatformHeader, platformApi } from 'api';
import * as authApi from 'api/auth';
import { getDomain } from 'utils';
import { withDefault, getUserModel, getPermissionModel } from 'api/models';
import { IUserProfile, UserProfile } from 'types/auth';
import { platformTokenKey, platformUserIdKey, editorUserIdKey } from 'constants/api';
import PLATFORM from 'constants/platform';

export const login = async ({ email = '', password = '' }) => {
    const urlEnd = '/auth/v1/login';
    const body = {
        username: email,
        password,
    };
    const response = await api.post<IUserProfile & { token: string }>(urlEnd, body);
    const result = withDefault(getUserModel)(response.data);
    const { token, id } = response.data;
    setHeader('Authorization', `Bearer ${token}`);
    jsCookie.set(platformTokenKey, token, { domain: getDomain() });
    jsCookie.set(editorUserIdKey, id, { domain: getDomain() });
    return result;
};

// /sso/v1/permission/{productId}
export const getPermission = async (username: string) => {
    const { productId } = PLATFORM;
    const urlEnd = `/sso/v1/permission/${productId}`;
    const config = {
        headers: {
            ProductKey: PLATFORM.productKey,
            Username: username,
        },
    };
    const body = {};
    const response = await api.post(urlEnd, body, config);
    jsCookie.set(editorUserIdKey, response.data.accountId, { domain: getDomain() });
    const result = withDefault(getPermissionModel)(response.data);
    return result;
};

export const getDealerPermission = async (code: string) => {
    const urlEnd = `api/v1/dealerUserAcl/permission/${code}`;
    const response = await api.get(urlEnd);
    jsCookie.set(editorUserIdKey, response.data.accountId, { domain: getDomain() });
    const result = withDefault(getPermissionModel)(response.data);
    return result;
};

export const v2login = async ({ email = '', password = '' }) => {
    const urlEnd = '/auth/v2/login';
    const body = {
        username: email,
        password,
    };
    const response = await api.post<IUserProfile & { token: string }>(urlEnd, body);
    const result = response.data;
    const { token, id } = response.data;
    jsCookie.set(platformTokenKey, token, { domain: getDomain() });
    jsCookie.set(platformUserIdKey, id, { domain: getDomain() });
    setPlatformHeader('Authorization', `Bearer ${token}`);
    setHeader('Authorization', `Bearer ${token}`);
    return result;
};

export const dealerLogin = async ({ email = '', password = '', code = '' }) => {
    const urlEnd = `/auth/v1/${code}/login`;
    const body = {
        username: email,
        password,
    };
    const response = await api.post<IUserProfile & { token: string }>(urlEnd, body);
    const result = response.data;
    const { token, id } = response.data;
    jsCookie.set(platformTokenKey, token, { domain: getDomain() });
    jsCookie.set(platformUserIdKey, id, { domain: getDomain() });
    setPlatformHeader('Authorization', `Bearer ${token}`);
    setHeader('Authorization', `Bearer ${token}`);
    return result;
};

export const logout = async () => {
    jsCookie.remove(platformTokenKey, { domain: getDomain() });
    jsCookie.remove(platformUserIdKey, { domain: getDomain() });
    jsCookie.remove(editorUserIdKey, { domain: getDomain() });
    removeHeader('Authorization');
    removePlatformHeader('Authorization');
};

export const updateMyAccount = async (myAccount: Partial<UserProfile>) => {
    const platformToken = jsCookie.get(platformTokenKey);
    const config = {
        headers: {
            SSOToken: platformToken,
        },
    };
    const urlEnd = '/api/v2/account';
    const body = myAccount;
    const response = await api.put(urlEnd, body, config);
    const result = response.data;
    return result;
};

export const autoLogin = async () => {
    const platformToken = jsCookie.get(platformTokenKey);
    const platformUserId = jsCookie.get(platformUserIdKey);
    const editorUserId = jsCookie.get(editorUserIdKey);
    if (platformToken && (platformUserId || editorUserId)) {
        setHeader('Authorization', `Bearer ${platformToken}`);
        setPlatformHeader('Authorization', `Bearer ${platformToken}`);
        const urlEnd = platformUserId ? `api/v2/account/platform/${platformUserId}` : `/api/v2/account/${editorUserId}`;
        const response = await api.get<IUserProfile>(urlEnd);
        const result = withDefault(getUserModel)(response.data);
        return result;
    }
    return Promise.reject('autoLogin failed');
};

export const changePassword = async (oldPwd: string, newPwd: string) => {
    const platformToken = jsCookie.get(platformTokenKey);
    const config = {
        headers: {
            SSOToken: platformToken,
        },
    };
    try {
        const urlEnd = '/api/v2/account/changePwd';
        const body = {
            oldPwd: btoa(oldPwd),
            newPwd: btoa(newPwd),
        };
        const response = await api.post(urlEnd, body, config);
        const result = response.data;
        return result;
    } catch (err) {
        return Promise.reject(err);
    }
};

export const forgotPassword = async (forgotPwdForm: { username: string; email: string; token: string }) => {
    // sso
    const config = {
        headers: {
            'captcha-response': forgotPwdForm.token,
        },
    };
    const urlEnd = '/public/v1/account/forgotPwd';
    const body = {
        username: forgotPwdForm.username,
        email: forgotPwdForm.email,
    };
    const response = await platformApi.post(urlEnd, body, config);
    const result = response.data;
    return result;
};
