import * as sceneApi from 'api/scene';
import { ViewerAcl } from 'types/scene';

export const ACTION_TYPES = {
    setSceneFeature: 'setSceneFeature',
} as const;

export const createSetSceneFeatureAction = (sceneFeature: Partial<ViewerAcl>) => ({
    type: ACTION_TYPES.setSceneFeature,
    payload: sceneFeature,
});

export const getSceneFeature = (sceneId: string) => async (dispatch) => {
    const sceneFeature = await sceneApi.getFeatures(sceneId);
    dispatch(createSetSceneFeatureAction(sceneFeature));
    return sceneFeature;
};

export const updateSceneFeature = (featureId: string, sceneFeature: Partial<ViewerAcl>) => async (dispatch) => {
    const result = await sceneApi.updateFeatures(featureId, sceneFeature);
    dispatch(createSetSceneFeatureAction(sceneFeature));
    return result;
};
