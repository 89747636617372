import api, { getErrorMsg } from 'api';
import { DealerInfo } from 'types/dealer';

export const getDealerInfo = async (code: string) => {
    try {
        const urlEnd = `/public/v1/dealerConfig/${code}`;
        const response = await api.get(urlEnd);
        const result = response.data;
        return result;
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};
