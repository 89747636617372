import api from 'api';

export type Category = {
    id: string;
    type: string;
    name: string;
    childrens: string[];
};

const baseUrlEnd = '/public/v1/category';

export const getTemplateCategories = async () => {
    const response = await api.get<Category[]>(baseUrlEnd);
    return response.data;
};
