// react
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTION_TYPES } from 'actions/tutorial';

// material
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// components hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';

// component
import TutorialStepContainer, { TutorialStep } from './TutorialStepContainer';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: '12px',
        width: '100%',
        height: 'auto',
        color: '#333333',
        fontSize: '24px',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    description: {
        width: '100%',
        fontSize: '18px',
        color: '#696969',
        textAlign: 'left',
    },
    footer: {
        margin: 'auto 0 0 0',
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    skip: {
        marginRight: 30,
        color: theme.palette.primary.main,
        fontSize: 19,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    continueBtn: {
        width: 110,
        height: 44,
        background: theme.palette.primary.main,
        borderRadius: 6,
        color: '#fff',
        fontSize: 19,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    closeBtn: {
        position: 'absolute',
        top: 14,
        right: 14,
    },
}));

interface IStepProps {
    title: string;
    description: string;
    skip: string;
    prev: string;
    nextStep: string;
    nextPath: string;
    currentStep: number;
}

const TutorialNextStep: React.FC<IStepProps> = ({
    title,
    description,
    skip,
    prev,
    nextStep,
    nextPath,
    currentStep,
}: IStepProps) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const tutorial = useAppSelector((store) => store.tutorial);
    const isPrev = tutorial.currentStep !== TutorialStep.STEP1 && tutorial.currentStep !== TutorialStep.STEP2;

    const handleNextStep = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch({ type: ACTION_TYPES.nextStep, payload: tutorial.currentStep + 1 });
        if (tutorial.currentStep === currentStep) navigate(nextPath);
    };

    const handleSkip = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch({ type: ACTION_TYPES.nextStep, payload: tutorial.currentStep - tutorial.currentStep });
        navigate('/');
    };

    const handleStepMutation = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch({ type: ACTION_TYPES.nextStep, payload: tutorial.currentStep - 1 });
        if (tutorial.currentStep === TutorialStep.STEP3) return navigate('/material-manage');
        return navigate('/');
    };

    return (
        <TutorialStepContainer>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            <div className={styles.footer}>
                <div
                    className={styles.skip}
                    onClick={isPrev ? handleStepMutation : handleSkip}
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex={0}
                >
                    {isPrev ? prev : skip}
                </div>
                <div
                    className={styles.continueBtn}
                    onClick={handleNextStep}
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex={0}
                >
                    {nextStep}
                </div>
            </div>
            <div onClick={handleSkip} role="button" onKeyPress={() => {}} tabIndex={0}>
                <CloseIcon className={styles.closeBtn} />
            </div>
        </TutorialStepContainer>
    );
};

export default TutorialNextStep;
