import React, { useState, useImperativeHandle, forwardRef } from 'react';
import classnames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';

import { ReactComponent as ToastSuccess } from 'images/icon/toast-success.svg';
import { ReactComponent as ToastFailed } from 'images/icon/toast-failed.svg';
import { ReactComponent as ToastSuccessLarge } from 'images/icon/toast-success-large.svg';

import styles from './index.module.css';

interface Props {
    className?: string;
    duration?: number;
    active?: boolean;
    title?: string;
    subTitle?: () => JSX.Element;
    size?: string;
}

const Toast = forwardRef<any, Props>(
    (
        {
            className,
            duration,
            active = false,
            title: defaultTitle,
            subTitle: defaultSubTitle,
            size: defaultSize,
        }: Props,
        ref,
    ) => {
        const [isOpen, setIsOpen] = useState(active);
        const [title, setTitle] = useState(defaultTitle);
        const [subTitle, setSubTitle] = useState(defaultSubTitle);
        const [size, setSize] = useState(defaultSize);
        const [type, setType] = useState('success');

        useImperativeHandle(ref, () => ({
            showToast: ({
                title: propTitle = '',
                subTitle: propSubTitle,
                type: propType = 'success',
                size: propSize,
            }: {
                title: string | Error;
                subTitle: any;
                type: string;
                size?: string;
            }): void => {
                if (propTitle instanceof Error) {
                    setTitle(propTitle.message);
                } else {
                    setTitle(propTitle);
                }
                setSubTitle(propSubTitle);
                if (propSize) {
                    setSize(propSize);
                }
                setType(propType);
                setIsOpen(true);
            },
        }));

        return (
            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={() => setIsOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                TransitionComponent={Fade}
            >
                <div className={classnames(styles.toastWrapper, { [styles.large]: size === 'large' }, className)}>
                    {size === 'normal' && (
                        <>
                            {type === 'success' ? <ToastSuccess /> : <ToastFailed />}
                            <p>{title}</p>
                        </>
                    )}
                    {size === 'large' && type === 'success' && (
                        <>
                            <div>
                                {type === 'success' ? <ToastSuccessLarge /> : <ToastFailed />}
                                <p>{title}</p>
                            </div>
                            <p>{subTitle}</p>
                        </>
                    )}
                    {size === 'large' && type === 'failed' && (
                        <>
                            <ToastFailed />
                            <p>{title}</p>
                        </>
                    )}
                </div>
            </Snackbar>
        );
    },
);
Toast.defaultProps = {
    className: undefined,
    duration: 2000,
    active: false,
    title: '',
    subTitle: () => <></>,
    size: 'normal',
};

export default Toast;
