import React from 'react';
import clx from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import PLANS from 'constants/plans';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { postSubscribePackage } from 'api/package';
import { useStripe, CardNumberElement, CardCvcElement, CardExpiryElement, useElements } from '@stripe/react-stripe-js';
import { StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import styles from './index.module.css';

const PayModal = ({ packageId, onClose, onNext }: { packageId: string; onClose: () => void; onNext: () => void }) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const plan = PLANS.find((plan) => plan.packageId === packageId);
    const [validcreditCardNumber, setValidCreditCardNumber] = React.useState(false);
    const [validExpDate, setValidExpDate] = React.useState(false);
    const [validCvc, setValidCvc] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string>('');

    if (!plan || !stripe || !elements) return null;

    const styleOptions = {
        style: {
            base: {
                color: 'rgba(51, 51, 51, 1)',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#adadad',
                },
            },
            invalid: {
                color: '#d61313',
            },
        },
        classes: {
            base: styles['pay-modal-stripe'],
            invalid: styles['pay-modal-stripe--invalid'],
        },
    };

    const validate = () => {
        if (!loading && validcreditCardNumber && validExpDate && validCvc) {
            return true;
        }
        return false;
    };

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { token, error } = await stripe.createToken(
                elements.getElement(CardNumberElement) as StripeCardNumberElement,
            );
            if (error) throw new Error(error.message);
            await postSubscribePackage(packageId, token.id);
            onNext();
        } catch (error) {
            const errorMessage = typeof error === 'string' ? error : (error as StripeError).message;
            setError(errorMessage || '');
        } finally {
            setLoading(false);
        }
    };

    const closeNotification = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setError('');
    };

    return (
        <Modal open>
            <div className={styles['pay-modal-wrapper']}>
                <button
                    onClick={() => {
                        if (!loading) onClose();
                    }}
                    type="button"
                    className={styles['close-button']}
                >
                    <CloseIcon />
                </button>
                <div className={styles['plan-card']}>
                    <span className={styles['plan-card-check-mark']}>
                        <CheckIcon />
                    </span>
                    <h1 className={styles['plan-card-title']}>{t(plan.title)}</h1>
                    <p className={styles['plan-card-price']}>
                        {t(plan.currency)} $<span className={styles['plan-card-price-number']}>{plan.price}</span>
                        {' /'}
                        {t(plan.interval)}
                    </p>
                    <div className={styles['plan-card-footer']}>{t(plan.description)}</div>
                </div>
                <p className={styles['pay-modal-hint']}>
                    {t('upgrade.priceHint', {
                        price: plan.price,
                        interval: t(plan.interval),
                    })}
                </p>
                <form onSubmit={submit}>
                    <h2 className={styles['pay-modal-title']}>{t('myAccount.creditCard')}</h2>
                    <p className={styles['pay-modal-field-label']}>
                        {t('myAccount.creditCardNum')}
                        <span className={styles['pay-modal-field-required-mark']}>*</span>
                    </p>
                    <CardNumberElement
                        id="card-number"
                        options={styleOptions}
                        onChange={(e) => {
                            setValidCreditCardNumber(e.complete);
                        }}
                    />
                    <p className={styles['pay-modal-field-label']}>
                        {t('myAccount.creditCardExpirationDate')}
                        <span className={styles['pay-modal-field-required-mark']}>*</span>
                    </p>
                    <CardExpiryElement
                        id="card-expire"
                        options={styleOptions}
                        onChange={(e) => {
                            setValidExpDate(e.complete);
                        }}
                    />
                    <p className={styles['pay-modal-field-label']}>
                        CVC
                        <span className={styles['pay-modal-field-required-mark']}>*</span>
                    </p>
                    <CardCvcElement
                        id="card-cvc"
                        options={styleOptions}
                        onChange={(e) => {
                            setValidCvc(e.complete);
                        }}
                    />
                    <button
                        type="submit"
                        disabled={!validate()}
                        className={clx(
                            styles['pay-modal-submit-button'],
                            validate() ? '' : styles['pay-modal-submit-button--disable'],
                        )}
                    >
                        {loading ? <CircularProgress size={24} color="primary" /> : t('common.confirm')}
                    </button>
                </form>
                <Snackbar open={!!error} autoHideDuration={10000} onClose={closeNotification}>
                    <MuiAlert onClose={closeNotification} severity="error">
                        {error}
                    </MuiAlert>
                </Snackbar>
            </div>
        </Modal>
    );
};

export default PayModal;
