import { AnyAction } from 'redux';

import { ACTION_TYPES } from 'actions/payment';
import { Payment } from 'types/creditCard';

interface Action extends AnyAction {
    type: keyof typeof ACTION_TYPES;
}

const initialState: Payment = {
    content: [],
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: false,
        sort: { empty: false, unsorted: false, sorted: false },
        empty: false,
        sorted: false,
        unsorted: false,
        unpaged: false,
    },
    size: 0,
    sort: { empty: false, unsorted: false, sorted: false },
    totalElements: 0,
    totalPages: 0,
};

const paymentReducer = (state: Payment = initialState, action: Action): Payment => {
    switch (action.type) {
        case ACTION_TYPES.getPayment:
            return action.payload;
        default:
            return state;
    }
};

export default paymentReducer;
