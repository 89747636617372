import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import clx from 'classnames';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PLATFORM_KEYS from 'constants/platform';
import STRIPE_KEY from 'constants/stripe';
import { getScenes } from 'actions/scenes';
import { getPayment } from 'actions/payment';
import { useAppSelector, useAppDispatch } from 'hooks/store';
import UPGRADABLE_PACKAGE_IDS from 'constants/upgradablePackageIds';
import { getPackageInfo } from 'store';
import AddOnModal from '../AddOnModal';
import ProfilePanel from '../ProfilePanel';
import SubscribeModal from '../SubscribeModal';

const useStyles = makeStyles((theme) => {
    const classes = createStyles({
        chip: {
            margin: '0 5px',
            padding: '5px 8px',
            borderRadius: '20px',
            backgroundColor: theme.palette.primary.light,
        },
        buttonColor: {
            color: 'inherit',
        },
        close: {
            position: 'absolute',
            right: 0,
            top: 0,
        },
    });
    return classes;
});

const stripePromise = loadStripe(STRIPE_KEY);

const PackagePanel = () => {
    const classes = useStyles();
    const dispath = useAppDispatch();
    const { t, i18n } = useTranslation();
    const [openAddOnModal, setOpenAddOnModal] = useState(false);
    const [openSubscribleModal, setOpenSubscribleModal] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [anchorEle, setAnchorEle] = useState(null);
    const auth = useAppSelector((store) => store.auth);
    const scenes = useAppSelector((store) => store.scenes);
    const dealer = useAppSelector((store) => store.dealer);
    const packageInfo = useAppSelector(getPackageInfo);
    const publicScenes = scenes.filter((scene) => scene.status === 'public');
    const isProfileShow = auth && !loading;
    const isPackageShow = auth && !loading && !dealer.isDealer;
    const { productId, type } = packageInfo;
    const showAddOnBtn = productId === PLATFORM_KEYS.productId && type === 'stripeUsage';

    const handleClick = (event) => {
        setAnchorEle(anchorEle ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEle(null);
    };

    useEffect(() => {
        if (auth) {
            setLoading(true);

            Promise.all([dispath(getPayment()), dispath(getScenes())])
                .catch((error) => console.error(error)) // sceneObj not found
                .finally(() => setLoading(false));
        }
    }, [auth]);

    return (
        <Box display="flex">
            {isPackageShow && (
                <Box display="flex" alignItems="center">
                    {UPGRADABLE_PACKAGE_IDS.includes(packageInfo.id || '') ? (
                        <Button className={classes.buttonColor} onClick={() => setOpenSubscribleModal(true)}>
                            <Typography variant="body2">{t('addOn.upgradePlan')}</Typography>
                        </Button>
                    ) : null}
                    <Typography variant="body2">{t('package.currentPackage')} : </Typography>
                    <Typography className={classes.chip} variant="body2">
                        {t(`packageName.${packageInfo.name}`)}
                    </Typography>
                    <IconButton className={classes.buttonColor} onClick={handleClick}>
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </Box>
            )}
            {isProfileShow && <ProfilePanel />}
            <Popper open={Boolean(anchorEle)} anchorEl={anchorEle} transition disablePortal>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                            <Paper>
                                <Box p={1}>
                                    <Box p={1}>
                                        <Typography align="center">{t('package.package')}</Typography>
                                    </Box>
                                    <IconButton className={classes.close} onClick={handleClose}>
                                        <ClearIcon />
                                    </IconButton>
                                    <Box p={1}>
                                        <Divider></Divider>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                                        <Typography variant="body2">{t('package.currentPackage')}:</Typography>
                                        <Chip label={t(`packageName.${packageInfo.name}`)} />
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" p={1}>
                                        <Typography variant="body2">{t('package.expiredAt')}:</Typography>
                                        <Typography variant="body2">
                                            {dayjs(packageInfo.expiredAt).format('YYYY/MM/DD')}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" p={1}>
                                        <Typography variant="body2">{t('package.publicSceneAmount')}:</Typography>
                                        <Typography variant="body2">
                                            {publicScenes.length} / {packageInfo.maxLiveTours}
                                        </Typography>
                                    </Box>
                                    <Box p={1}>
                                        <Divider></Divider>
                                    </Box>
                                    {showAddOnBtn && (
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                onClick={() => setOpenAddOnModal(true)}
                                            >
                                                {t('addOn.seePlan')}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
            <Elements stripe={stripePromise}>
                <>
                    <AddOnModal open={openAddOnModal} setOpen={setOpenAddOnModal} />
                    {openSubscribleModal && <SubscribeModal onClose={() => setOpenSubscribleModal(false)} />}
                </>
            </Elements>
        </Box>
    );
};

export default PackagePanel;
