import * as authApi from 'api/auth';
import { UserProfile } from 'types/auth';
import { setPermission } from 'actions/permission';

export const ACTION_TYPES = {
    setUser: 'setUser',
} as const;

export const createSetUserAction = (userProfile: UserProfile) => ({
    type: ACTION_TYPES.setUser,
    payload: userProfile,
});

export const updateMyAccountAction = (userProfile: Partial<UserProfile>) => ({
    type: ACTION_TYPES.setUser,
    payload: userProfile,
});

export const login = (loginInfo: { email: string; password: string }) => async (dispatch) => {
    const userInfo = await authApi.v2login(loginInfo);
    const permissions = await authApi.getPermission(userInfo.username);
    dispatch(createSetUserAction({ ...userInfo, id: permissions.accountId }));
    dispatch(setPermission(permissions));
};

export const dealerLogin = (loginInfo: { email: string; password: string; code: string }) => async (dispatch) => {
    const userInfo = await authApi.dealerLogin(loginInfo);
    const permissions = await authApi.getDealerPermission(userInfo.username);
    dispatch(createSetUserAction({ ...userInfo, id: permissions.accountId }));
    dispatch(setPermission(permissions));
};

export const autoLogin = () => async (dispatch) => {
    const userInfo = await authApi.autoLogin();

    const permissions = userInfo.dealerCode
        ? await authApi.getDealerPermission(userInfo.dealerCode)
        : await authApi.getPermission(userInfo.username);
    dispatch(createSetUserAction(userInfo));
    dispatch(setPermission(permissions));
};

export const logout = () => async (dispatch) => {
    authApi.logout();
    dispatch(createSetUserAction(null));
};

export const updateMyAccount = (myAccountInfo: Partial<UserProfile>) => async (dispatch) => {
    const res = await authApi.updateMyAccount(myAccountInfo);
    if (res.code === '00000' && res.msg === 'success') {
        dispatch(updateMyAccountAction(myAccountInfo));
    }
};
