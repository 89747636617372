import { AnyAction } from 'redux';

import { ACTION_TYPES } from 'actions/permission';
import { Permission } from 'types/permission';
import { withDefault, getPermissionModel } from 'api/models';

interface Action extends AnyAction {
    type: keyof typeof ACTION_TYPES;
}

const initialState = withDefault(getPermissionModel)();

const permissionReducer = (state: Permission = initialState, action: Action): Permission => {
    switch (action.type) {
        case ACTION_TYPES.setPermission:
            return action.payload;
        default:
            return state;
    }
};

export default permissionReducer;
