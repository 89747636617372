import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import completeGif from 'images/gif/check-circle.gif';
import styles from './index.module.css';

const CompleteModal = () => {
    const { t } = useTranslation();

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <Modal open>
            <div className={styles['complete-modal-wrapper']}>
                <img className={styles['complete-modal-complete-image']} src={completeGif} alt="complete" />
                <h1 className={styles['complete-modal-title']}>{t('common.complete')}</h1>
                <p className={styles['complete-modal-hint']}>{t('upgrade.completeDescription')}</p>
                <button onClick={reloadPage} type="button" className={styles['complete-modal-button']}>
                    {t('upgrade.completeButton')}
                </button>
            </div>
        </Modal>
    );
};

export default CompleteModal;
