import { AnyAction } from 'redux';

import { ACTION_TYPES } from 'actions/dealer';
import { DealerInfo } from 'types/dealer';

interface Action extends AnyAction {
    type: keyof typeof ACTION_TYPES;
}

const initialState: DealerInfo = {
    isDealer: false,
    code: '',
    title: 'Scene Editor',
    favicon: '',
    brandLogo: '',
    productLogo: '',
    productName: '',
    mainColor: '#fff',
    subColor: '#fff',
    homePage: '',
    openLink: '',
    loginBg: '',
    colorMode: 'light',
};

const dealerReducer = (state = initialState, action: Action): DealerInfo => {
    switch (action.type) {
        case ACTION_TYPES.setDealerInfo:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default dealerReducer;
