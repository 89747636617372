// react
import React, { ReactNode } from 'react';

// material
import { makeStyles } from '@material-ui/core/styles';

// components hooks
import { useAppSelector } from 'hooks/store';

import clx from 'classnames';

export enum TutorialStep {
    SKIP,
    STEP1,
    STEP2,
    STEP3,
    STEP4,
    STEP5,
    FINISH,
}

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 700,
        letterSpacing: 'initial',
        lineHeight: 'initial',
    },
    flexStartCenter: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    step_5_container: {
        position: 'absolute',
        width: '400px',
        height: '200px',
        right: '-470px',
        bottom: '-70px',
        opacity: '1 !important',
        pointerEvents: 'auto',
    },
    container: {
        position: 'absolute',
        width: '400px',
        height: '200px',
        right: '-450px',
    },
    panel: {
        flexDirection: 'column',
        padding: '18px 20px 14px',
        position: 'absolute',
        width: 400,
        height: 200,
        background: '#fff',
        boxShadow: '3px 3px 6px #00000029',
        border: '1px solid #EFEFEF',
        borderRadius: 8,
        zIndex: theme.zIndex.tooltip,
        '& svg': {
            fill: '#888888 !important',
        },
    },
    triangle: {
        top: 0,
        bottom: 0,
        marginTop: 'auto',
        marginBottom: 'auto',
        position: 'absolute',
        left: '-25px',
        width: 50,
        height: 50,
        boxShadow: '0px 3px 6px #00000029',
        backgroundColor: '#fff',
        transform: 'rotate(45deg)',
        transformOrigin: 'center center',
        zIndex: theme.zIndex.snackbar,
    },
}));

interface IProps {
    children: ReactNode;
}

const TutorialStepContainer: React.FC<IProps> = ({ children }: IProps) => {
    const styles = useStyles();
    const tutorial = useAppSelector((store) => store.tutorial);

    return (
        <div
            className={clx(
                {
                    [styles.container]: tutorial.currentStep !== 5,
                    [styles.step_5_container]: tutorial.currentStep === 5,
                },
                styles.root,
            )}
        >
            <div className={clx(styles.panel, styles.flexStartCenter)}>{children}</div>
            <div className={styles.triangle} />
        </div>
    );
};

export default TutorialStepContainer;
