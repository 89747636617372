import * as sceneApi from 'api/scene';
import { Scene } from 'types/scene';

export const ACTION_TYPES = {
    getScenes: 'getScenes',
} as const;

export const createGetScenesAction = (scenes: Scene[]) => ({
    type: ACTION_TYPES.getScenes,
    payload: scenes,
});

export const getScenes = () => async (dispatch) => {
    const scenes = await sceneApi.getScenes();
    dispatch(createGetScenesAction(scenes));
    return scenes;
};
