// dev
// 月：ff8081817c2a61ae017c35e9a3740070
// 年：ff8081817c2a61ae017c35e955fb005e
// 加購 tag: ff80818180f5643d0180fab8424d0004
// prod
// 月：ff8081817cde6c45017ce4f4ce04001c
// 年：ff8081817cde6c45017ce4f420130000
// 加購 tag: ff80818180fa50610180fabe51830000

const PACKAGE_ID = {
    development: {
        monthly: 'ff8081817c2a61ae017c35e9a3740070',
        yearly: 'ff8081817c2a61ae017c35e955fb005e',
        addOnTags: 'ff80818180f5643d0180fab8424d0004',
    },
    test: {
        monthly: 'ff8081817c2a61ae017c35e9a3740070',
        yearly: 'ff8081817c2a61ae017c35e955fb005e',
        addOnTags: 'ff80818180f5643d0180fab8424d0004',
    },
    production: {
        monthly: 'ff8081817cde6c45017ce4f4ce04001c',
        yearly: 'ff8081817cde6c45017ce4f420130000',
        addOnTags: 'ff80818180fa50610180fabe51830000',
    },
};

export default PACKAGE_ID[process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV];
