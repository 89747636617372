type Feature = {
    title: string;
    value?: string | number;
    unit?: string;
    per?: string;
};

export type Plan = {
    packageId: string;
    title: string;
    description: string;
    price: number;
    currency: string;
    interval: string;
    features: Feature[];
    color: string;
};

const PACKAGE_IDS = {
    development: {
        free: 'ff8081817cef8b83017cffc0fa440000',
        basic: 'ff808181805e693101806a0ea48a0030',
        pro: 'ff8081817c269b50017c2a4d8ed50028',
    },
    test: {
        free: 'ff8081817cef8b83017cffc0fa440000',
        basic: 'ff808181805e693101806a0ea48a0030',
        pro: 'ff8081817c269b50017c2a4d8ed50028',
    },
    production: {
        free: 'ff8081817cef94a4017cff87d8150000',
        basic: 'ff8081818069e7ce018069f3c5910000',
        pro: 'ff8081817cde6c45017ce4f4ce04001c',
    },
};

const packageIds = PACKAGE_IDS[process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV];

const FEATURE_SCENE_SPACE_EDITOR = {
    title: 'upgrade.features.spaceEditor',
};

const FEATURE_MAX_SCENE = {
    title: 'upgrade.features.maxScene',
    value: 'common.unlimited',
};

const FEATURE_MAX_TAG_PER_SCENE = {
    title: 'upgrade.features.maxTagPerScene',
    value: '20',
    unit: 'upgrade.features.item',
    per: 'upgrade.features.perScene',
};

const FEATURE_SCENE_PUBLICABLE = {
    title: 'upgrade.features.publicableScene',
    value: '1',
    unit: 'upgrade.features.item',
    per: 'common.month',
};

const FEATURE_DATA_ANALYZE = {
    title: 'upgrade.features.dataAnalysis',
};

const FEATURE_MEETING_SCHEDULE = {
    title: 'upgrade.features.reserveMeeting',
};

const FEATURE_MESSAGE = {
    title: 'upgrade.features.messageFunction',
};

const FEATURE_SOCIAL = {
    title: 'upgrade.features.socialFunction',
};

const PLANS: Plan[] = [
    {
        packageId: packageIds.free,
        title: 'upgrade.features.freeVersion',
        description: 'upgrade.features.freeVersionDescription',
        price: 0,
        currency: 'USD',
        interval: 'common.month',
        features: [FEATURE_SCENE_SPACE_EDITOR, FEATURE_MAX_SCENE, FEATURE_MAX_TAG_PER_SCENE],
        color: '#71A1E8',
    },
    {
        packageId: packageIds.basic,
        title: 'upgrade.features.basic',
        description: 'upgrade.features.basicDescription',
        price: 20,
        currency: 'USD',
        interval: 'common.month',
        features: [
            FEATURE_SCENE_SPACE_EDITOR,
            FEATURE_MAX_SCENE,
            FEATURE_MAX_TAG_PER_SCENE,
            FEATURE_SCENE_PUBLICABLE,
            FEATURE_DATA_ANALYZE,
        ],
        color: '#264679',
    },
    {
        packageId: packageIds.pro,
        title: 'upgrade.features.pro',
        description: 'upgrade.features.proDescription',
        price: 129,
        currency: 'USD',
        interval: 'common.month',
        features: [
            FEATURE_SCENE_SPACE_EDITOR,
            FEATURE_MAX_SCENE,
            { ...FEATURE_MAX_TAG_PER_SCENE, value: 50 },
            FEATURE_SCENE_PUBLICABLE,
            FEATURE_DATA_ANALYZE,
            FEATURE_MEETING_SCHEDULE,
            FEATURE_MESSAGE,
            FEATURE_SOCIAL,
        ],
        color: 'linear-gradient(90.2deg, #ED0973 0.14%, #662D91 99.83%)',
    },
];

export default PLANS;
