import { uploadFile } from 'api/s3';

import i18n from 'i18next';
import imageCompression from 'browser-image-compression';

const defaultSetting = {
    maxSize: 5 * 1024 * 1024,
    regExp: /png$|PNG$|jpg$|JPG$|jpeg$|JPEG$/i,
    accept: 'image/*',
};

export const verifyFile = (file: File, config: Partial<typeof defaultSetting> = defaultSetting): string => {
    const setting = { ...defaultSetting, ...config };
    let result = '';
    if (!setting.regExp.test(file.name)) {
        result = i18n.t('toast.invalidFile');
    }
    if (file.size > setting.maxSize) {
        result = i18n.t('toast.fileTooLarge');
    }
    return result;
};

export const verifyEmail = (email: string) => {
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
    return emailRegex.test(email);
};

export const verifyUrl = (url: string) => {
    const urlRegex = /^((https|http)?:\/\/)[^\s]+/;
    return urlRegex.test(url);
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
    const blob: any = theBlob;
    blob.lastModifiedDate = new Date();
    blob.name = `${fileName}.${blob.type.split('/')[1]}`;
    return blob;
};

export const getBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const getRandom = (digit: number) =>
    Math.random()
        .toString()
        .substring(2, digit + 2);

// reference https://stackoverflow.com/questions/11401897/get-the-current-domain-name-with-javascript-not-the-path-etc
export const getDomain = (url = window.location.href, subdomain = false) => {
    if (window.location.hostname === 'localhost') return 'localhost';
    const host = url.replace(/(https?:\/\/)?(www.)?/i, '');
    let result = host;
    if (!subdomain) {
        const paths = host.split('.');
        result = paths.slice(paths.length - 2).join('.');
    }

    if (result.indexOf('/') !== -1) {
        return result.split('/')[0];
    }

    return result;
};

export const rows2Csv = (rows: string[][]) => {
    const csv = encodeURIComponent(rows.map((e: any) => e.join(',')).join('\n'));
    return `data:text/csv;charset=utf-8,\uFEFF${csv}`;
};

export const downloadCSVFile = (file: string, filename = 'file') => {
    const link = document.createElement('a');
    link.href = file;
    link.download = `${filename}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const compressImage = async (file: File, option?: { maxWidthOrHeight: number }) => {
    const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: file.type,
        ...option,
    };
    const compressed = await imageCompression(file, options);

    return compressed;
};

export const compressImgAndUploadFile = async (thumbnailFile: any, path: string) => {
    const splitFile = thumbnailFile.name.split('.');
    const fileExt = splitFile[splitFile.length - 1];
    const compressedFiles = await Promise.all([
        compressImage(thumbnailFile),
        compressImage(thumbnailFile, { maxWidthOrHeight: 100 }),
        compressImage(thumbnailFile, { maxWidthOrHeight: 400 }),
    ]);

    const filename = `${Date.now()}_${getRandom(7)}`;
    const standardName = `${filename}.${fileExt}`;
    const smallName = `${filename}_small.${fileExt}`;
    const mediumName = `${filename}_medium.${fileExt}`;

    const urls = await Promise.all([
        uploadFile(compressedFiles[0], path, standardName),
        uploadFile(compressedFiles[1], path, smallName),
        uploadFile(compressedFiles[2], path, mediumName),
    ]);

    return urls[0];
};

export const checksumFile = (currentFile: File, newFile: File) => {
    if (
        currentFile.name === newFile.name &&
        currentFile.lastModified === newFile.lastModified &&
        currentFile.size === newFile.size &&
        currentFile.type === newFile.type
    ) {
        return true;
    }
    return false;
};

export const isMsFeatureSite = (origin: string) => /d1ttcwbmv3x9ib\.amplifyapp\.com/.test(origin);
