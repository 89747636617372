export const ACTION_TYPES = {
    nextStep: 'nextStep',
} as const;

export const NextStep = (nextStep: number) => ({
    type: ACTION_TYPES.nextStep,
    payload: nextStep,
});

export const skipTutorial = () => ({
    type: ACTION_TYPES.nextStep,
    payload: 0,
});
