import api, { getErrorMsg } from 'api';
import { withDefault, getSceneModel } from 'api/models';
import { Scene, RoleAcl, ViewerAcl } from 'types/scene';

const format = withDefault(getSceneModel);

export const getScenes = async () => {
    const urlEnd = '/api/v1/scenes';
    const response = await api.get<Scene[]>(urlEnd);
    const result = response.data.map(format);
    return result;
};

export const deleteScene = async (sceneId: string) => {
    // if only has 1 data to delete, then use path to concat uuid
    const urlEnd = `/api/v1/scenes/${sceneId}?force=true`;
    const response = await api.delete<Scene>(urlEnd);
    const result = response.data;
    return result;
};

export const addScene = async (templateId = '', templateHistoryId = '') => {
    const urlEnd = '/api/v1/scenes';
    const body = { templateId, templateHistoryId };
    const response = await api.post<Scene>(urlEnd, body);
    const result = format(response.data);
    return result;
};

export const getSceneById = async (sceneId: string) => {
    const urlEnd = `/api/v1/scenes/${sceneId}`;
    const response = await api.get<Scene | null>(urlEnd);
    if (response.data == null) return Promise.reject();
    const result = format(response.data);
    return result;
};

export const updateScene = async (sceneInfo: Partial<Scene>) => {
    const urlEnd = '/api/v1/scenes';
    const body = { ...sceneInfo };
    delete body.likeNum; // to avoid updating likeNum to a scene with an outdated number
    const response = await api.put<Scene>(urlEnd, body);
    const result = format(response.data);
    return result;
};

export const updateSceneStatus = async (sceneInfo: Partial<Scene>) => {
    const urlEnd = '/api/v1/scenes/changeStatus';
    const body = sceneInfo;
    const response = await api.put(urlEnd, body);
    const result = response.data;
    return result;
};

export const transferScene = async (id: string, username: string) => {
    const urlEnd = '/api/v1/scenes/transfer';
    const body = { id, username };
    const response = await api.post(urlEnd, body);
    const result = response.data;
    return result;
};

export const copyScene = async (id: string, username: string) => {
    const urlEnd = '/api/v1/scenes/copy';
    const body = { id, username };
    const response = await api.post(urlEnd, body);
    const result = response.data;
    return result;
};

export const postSceneRoleAcl = async (sceneId: string, roleAcl: Partial<RoleAcl>) => {
    const urlEnd = '/api/v1/sceneRoleAcl';
    const body = {
        ...roleAcl,
        sceneId,
    };
    const response = await api.post(urlEnd, body);
    const result = response.data;
    return result;
};

export const postSceneViewerAcl = async (sceneId: string, viewerAcl: Partial<ViewerAcl>) => {
    const urlEnd = '/api/v1/sceneViewerAcl';
    const body = {
        ...viewerAcl,
        sceneId,
    };
    const response = await api.post<ViewerAcl>(urlEnd, body);
    const result = response.data;
    return result;
};

export const getFeatures = async (sceneId: string) => {
    try {
        const urlEnd = `/public/v1/sceneViewerAcl/scene/${sceneId}`;
        const reponse = await api.get<ViewerAcl>(urlEnd);

        return reponse.data;
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};

export const updateFeatures = async (featureId: string, viewerAcl: Partial<ViewerAcl>) => {
    const urlEnd = '/api/v1/sceneViewerAcl';
    const body = {
        ...viewerAcl,
        id: featureId,
    };
    const response = await api.put<ViewerAcl>(urlEnd, body);
    const result = response.data;
    return result;
};
