import { AnyAction } from 'redux';

import { ACTION_TYPES } from 'actions/sceneFeature';
import { ViewerAcl } from 'types/scene';
// import { withDefault, getSceneModel } from 'api/models';

interface Action extends AnyAction {
    type: keyof typeof ACTION_TYPES;
}

const initialState = {} as ViewerAcl;

const sceneFeatureReducer = (state: ViewerAcl = initialState, action: Action): ViewerAcl => {
    switch (action.type) {
        case ACTION_TYPES.setSceneFeature:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default sceneFeatureReducer;
