import React, { useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useStripe, CardNumberElement, CardCvcElement, CardExpiryElement, useElements } from '@stripe/react-stripe-js';
import {
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElementChangeEvent,
    loadStripe,
    StripeCardNumberElement,
} from '@stripe/stripe-js';
import PACKAGE_ID from 'constants/packageId';
import Toast from 'components/Utils/Toast';
import { getErrorMsg } from 'api';
import { postSubscription } from 'api/creditCard';

const initInvalid = {
    cardNum: true,
    cardExpDate: true,
    cardCVC: true,
};

const useStyles = makeStyles((theme) => {
    const classes = createStyles({
        modalContainer: {
            maxWidth: '500px',
            position: 'relative',
        },
        closeIcon: {
            position: 'absolute',
            top: '15px',
            right: '15px',
            cursor: 'pointer',
            '& > path': {
                width: '18px',
                height: '18px',
            },
        },
        stripeCustom: {
            border: '1px solid #ced4da',
            borderRadius: '4px',
            padding: ' 14px 24px',
        },
        invalidStripe: {
            border: '1px solid red',
        },
        topPlanWrap: {
            position: 'relative',
            width: '430px',
            marginTop: '35px',
            textAlign: 'center',
            border: `5px solid ${theme.palette.primary.mainDarker}`,
            borderRadius: '6px',
            color: '#264679',
            '& h4': {
                fontWeight: 500,
                fontSize: '20px',
                margin: '18px 0',
            },
        },
        checkIcon: {
            position: 'absolute',
            top: '15px',
            right: '15px',
            fill: theme.palette.primary.mainDarker,
            width: '30px',
            height: '30px',
        },
        planPrice: {
            fontWeight: 700,
            marginBottom: '20px',
            '& span': {
                fontSize: '48px',
            },
        },
        planBottomInfo: {
            color: '#ffffff',
            backgroundImage: 'linear-gradient(90deg, #264679 0%, #5FCBD1 100%)',
            padding: '10px',
        },
        planHint: {
            color: '#c4c4c4',
            fontSize: '14px',
            fontWeight: 500,
            textAlign: 'center',
            paddingTop: '5px',
        },
        creditCardTitle: {
            textAlign: 'center',
            fontSize: '20px',
        },
        formGroup: {
            marginBottom: '24px',
            '& label': {
                display: 'inline-block',
                paddingBottom: '5px',
            },
            '& span': {
                fontWeight: 700,
                color: theme.palette.primary.mainDarker,
            },
        },
        bottomBtn: {
            color: '#ffffff',
            backgroundColor: theme.palette.primary.mainDarker,
            borderRadius: '20px',
            padding: '7px',
            margin: '13px 0 35px 0',
            '&:hover': {
                backgroundColor: theme.palette.primary.mainDarker,
            },
        },
    });
    return classes;
});

type AddOnModalProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddOnModal = ({ open, setOpen }: AddOnModalProps) => {
    const classes = useStyles();
    const toastRef: React.MutableRefObject<any | null> = useRef(null);
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const [invalids, setInvalids] = useState(initInvalid);
    const [isLoading, setIsLoading] = useState(false);

    const options = {
        style: {
            base: {
                color: '#000000',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#adadad',
                },
            },
            invalid: {
                color: '#d61313',
            },
        },
        classes: {
            base: classes.stripeCustom,
            invalid: classes.invalidStripe,
        },
    };

    const handleFormInvalidsChange = (field: Partial<typeof initInvalid>) => {
        setInvalids({ ...invalids, ...field });
    };

    const handleCardNumInvalid = (e: StripeCardNumberElementChangeEvent) => {
        if (!e.complete) handleFormInvalidsChange({ cardNum: true });
        else handleFormInvalidsChange({ cardNum: false });
    };

    const handleExpDateInvalid = (e: StripeCardExpiryElementChangeEvent) => {
        if (!e.complete) handleFormInvalidsChange({ cardExpDate: true });
        else handleFormInvalidsChange({ cardExpDate: false });
    };

    const handleCVCInvalid = (e: StripeCardCvcElementChangeEvent) => {
        if (!e.complete) handleFormInvalidsChange({ cardCVC: true });
        else handleFormInvalidsChange({ cardCVC: false });
    };

    const hasInValidation = () => Object.values(invalids).some((field) => field);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stripe || !elements) return;
        setIsLoading(true);
        const { token, error } = await stripe.createToken(
            elements.getElement(CardNumberElement) as StripeCardNumberElement,
        );
        if (error) {
            toastRef.current?.showToast({
                title: t('toast.addOnFailed'),
                type: 'failed',
            });
            console.log('[error]', error);
        } else if (token) {
            const { id } = token;
            try {
                await postSubscription(PACKAGE_ID.addOnTags, id);
                toastRef.current?.showToast({
                    title: t('toast.addOnSuccessfully'),
                    type: 'success',
                });
            } catch (error) {
                toastRef.current?.showToast({
                    title: t('toast.addOnFailed'),
                    type: 'failed',
                });
                console.log('[error]', getErrorMsg(error));
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} classes={{ paperWidthSm: classes.modalContainer }}>
            <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
            <DialogContent>
                <div className={classes.topPlanWrap}>
                    <CheckCircleIcon className={classes.checkIcon} />
                    <h4>{t('addOn.addOnPlan')}</h4>
                    <div className={classes.planPrice}>
                        USD <span>10.00</span>/{t('common.month')}
                    </div>
                    <div className={classes.planBottomInfo}>{t('addOn.tagInformation')}</div>
                </div>
                <div className={classes.planHint}>{t('addOn.payment')}</div>
            </DialogContent>
            <DialogContent>
                <h4 className={classes.creditCardTitle}>{t('myAccount.creditCard')}</h4>
                <form onSubmit={handleSubmit}>
                    <div className={classes.formGroup}>
                        <label htmlFor="card-number">
                            {t('myAccount.creditCardNum')} <span>*</span>
                        </label>
                        <CardNumberElement
                            id="card-number"
                            options={options}
                            onChange={(event) => {
                                handleCardNumInvalid(event);
                            }}
                        />
                    </div>
                    <div className={classes.formGroup}>
                        <label htmlFor="card-expire">
                            {t('myAccount.creditCardExpirationDate')} <span>*</span>
                        </label>
                        <CardExpiryElement
                            id="card-expire"
                            options={options}
                            onChange={(event) => {
                                handleExpDateInvalid(event);
                            }}
                        />
                    </div>
                    <div className={classes.formGroup}>
                        <label htmlFor="card-cvc">
                            CVC <span>*</span>
                        </label>
                        <CardCvcElement
                            id="card-cvc"
                            options={options}
                            onChange={(event) => {
                                handleCVCInvalid(event);
                            }}
                        />
                    </div>
                    <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        disabled={!stripe || hasInValidation()}
                        type="submit"
                        className={classes.bottomBtn}
                    >
                        {isLoading ? <CircularProgress size={25} color="inherit" /> : t('common.confirm')}
                    </Button>
                </form>
            </DialogContent>
            <Toast ref={toastRef} title="" />
        </Dialog>
    );
};

export default AddOnModal;
