import React from 'react';
import ChoosePlanModal from './ChoosePlanModal';
import PayModal from './PayModal';
import CompleteModal from './CompleteModal';

enum SubscribeSteps {
    Choose,
    Pay,
    Complete,
}

const SubscribeModal = ({ onClose }: { onClose: () => void }) => {
    const [step, setStep] = React.useState<SubscribeSteps>(SubscribeSteps.Choose);
    const [packageId, setPackageId] = React.useState<string>('');

    return (
        <>
            {step === SubscribeSteps.Choose && (
                <ChoosePlanModal
                    onClose={onClose}
                    onNext={(packageId) => {
                        setPackageId(packageId);
                        setStep(SubscribeSteps.Pay);
                    }}
                />
            )}
            {step === SubscribeSteps.Pay && (
                <PayModal packageId={packageId} onClose={onClose} onNext={() => setStep(SubscribeSteps.Complete)} />
            )}
            {step === SubscribeSteps.Complete && <CompleteModal />}
        </>
    );
};

export default SubscribeModal;
