import axios from 'axios';
import SERVER from 'constants/server';

const axiosInstance = axios.create({
    baseURL: SERVER.SERVER_URL,
});

export const getErrorMsg = (err: any): string => err?.response?.data.msg || err?.response?.data.message;

export const setHeader = (key: string, value: string) => {
    axiosInstance.defaults.headers.common[key] = value;
};

export const removeHeader = (key: string) => {
    delete axiosInstance.defaults.headers.common[key];
};

export default axiosInstance;

export const platformApi = axios.create({
    baseURL: SERVER.PLATFORM_URL,
});

export const setPlatformHeader = (key: string, value: string) => {
    platformApi.defaults.headers.common[key] = value;
};

export const removePlatformHeader = (key: string) => {
    delete platformApi.defaults.headers.common[key];
};

export const marketSpaceApi = axios.create({
    baseURL: SERVER.MARKET_SPACE_SERVER,
});
