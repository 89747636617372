import React from 'react';
import SideMenu from '../../Pages/SideMenu';
import styles from './index.module.css';
import Banner from 'components/Banner';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useAppSelector } from 'hooks/store';
import { makeStyles } from '@material-ui/core';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
    },
}));

const MainLayout = () => {
    const classes = useStyles();
    const { isDealer } = useAppSelector((store) => store.dealer);
    return (
        <Paper className={classes.paper}>
            <div className={styles.wrapper}>
                <SideMenu />
                <div className={styles.container}>
                    <Outlet />
                </div>
                {!isDealer && <Banner />}
            </div>
        </Paper>
    );
};

export default MainLayout;
