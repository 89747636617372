const SERVER = {
    development: {
        BUCKET_NAME: '3d-maker-dev',
        LAMBDA_URL: 'https://z7z2j7dhho2pqbrs6ten2wu6ne0kitzp.lambda-url.ap-northeast-1.on.aws/',
        SERVER_URL: 'https://3dmaker-dev.istaging.com',
        EDITOR_URL: 'https://3deditor-dev.metamaker.istaging.com',
        VIEWER_URL: 'https://viewer-dev.metamaker.istaging.com',
        CDN_URL: 'https://3d-maker-dev.s3.ap-northeast-1.amazonaws.com',
        PLATFORM_URL: 'https://sso-dev.istaging.com',
        MARKET_SPACE_SERVER: 'https://designer-dev.istaging.com',
        MARKET_SPACE_URL: 'https://dev.d1ttcwbmv3x9ib.amplifyapp.com',
    },
    test: {
        BUCKET_NAME: 'vrmaker-pro-test',
        LAMBDA_URL: 'https://z7z2j7dhho2pqbrs6ten2wu6ne0kitzp.lambda-url.ap-northeast-1.on.aws/',
        SERVER_URL: 'https://3dmaker-test.istaging.com',
        EDITOR_URL: 'https://standard-test.d30t24qxas0jk7.amplifyapp.com',
        VIEWER_URL: 'https://test.ddxzgl5vhc7nn.amplifyapp.com',
        CDN_URL: 'https://cdn-images-test.istaging.com',
        PLATFORM_URL: 'https://sso-test.istaging.com',
        MARKET_SPACE_SERVER: 'https://designer-test.istaging.com',
        MARKET_SPACE_URL: 'https://dev.d1ttcwbmv3x9ib.amplifyapp.com',
    },
    production: {
        BUCKET_NAME: 'vrmaker-pro-prod',
        LAMBDA_URL: 'https://z7z2j7dhho2pqbrs6ten2wu6ne0kitzp.lambda-url.ap-northeast-1.on.aws/',
        SERVER_URL: 'https://3dmaker.istaging.com',
        EDITOR_URL: 'https://3deditor.metamaker.istaging.com',
        VIEWER_URL: 'https://viewer.metamaker.istaging.com',
        CDN_URL: 'https://cdn-images-prod.istaging.com',
        PLATFORM_URL: 'https://sso.istaging.com',
        MARKET_SPACE_SERVER: 'https://designer.istaging.com',
        MARKET_SPACE_URL: 'https://marketspace.istaging.com',
    },
};

export default SERVER[process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV];
