import React from 'react';
import clx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Plan } from 'constants/plans';
import { ReactComponent as CheckIcon } from 'images/icon/check.svg';
import { ReactComponent as StarYellowIcon } from 'images/icon/star-yellow.svg';
import styles from './index.module.css';

const PlanCard = ({
    plan,
    packageId,
    onClick = () => {},
}: {
    plan: Plan;
    packageId: string;
    onClick: (packageId: string) => void;
}) => {
    const { t } = useTranslation();

    const disabled = packageId === plan.packageId;

    return (
        <div className={styles['plan-card']}>
            <div className={styles['plan-card-header']} style={{ background: plan.color }}>
                <h1 className={styles['plan-card-title']}>{t(plan.title)}</h1>
                <p className={styles['plan-card-description']}>{t(plan.description)}</p>
                <StarYellowIcon className={styles['plan-card-star-icon']} />
            </div>
            <div className={styles['plan-card-content']}>
                <h2>
                    ${plan.price}
                    <span className={styles['plan-card-price-unit']}> {`${plan.currency} / ${t(plan.interval)}`}</span>
                </h2>
                <button
                    onClick={() => onClick(plan.packageId)}
                    type="button"
                    className={clx(
                        styles['plan-card-upgrade-button'],
                        disabled ? styles['plan-card-upgrade-button--disable'] : '',
                    )}
                    style={disabled ? {} : { background: plan.color }}
                    disabled={disabled}
                >
                    {disabled ? t('upgrade.currentPlan') : t('common.upgrade')}
                </button>
                {plan.features.map((feature) => (
                    <div key={feature.title} className={styles['plan-card-feature']}>
                        <CheckIcon className={styles['plan-card-feature-icon']} />
                        <p className={styles['plan-card-feature-content']}>
                            {' '}
                            {t(feature.title)}
                            {feature.value ? (
                                <>
                                    {' : '}
                                    <span className={styles['plan-card-feature-content-em']}>
                                        <span>{t(feature.value.toString() || '')}</span> {t(feature.unit || '')}
                                        {feature.per ? (
                                            <>
                                                {' / '}
                                                <span>{t(feature.per)}</span>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PlanCard;
