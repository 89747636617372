import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store';
import './index.css';
import packageJson from '../package.json';
import CookieBanner from 'components/CookieBanner/CookieBanner';

console.log(`version: ${packageJson.version}`);

const root = createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
        <CookieBanner />
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
