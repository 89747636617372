// react, redux
import React, { useState, useRef, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import clx from 'classnames';

// material ui, css
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ClearIcon from '@material-ui/icons/Clear';

// types
import { UserProfile } from 'types/auth';

// components, hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';

// api, auth
import { logout } from 'actions/auth';

import { useTranslation } from 'react-i18next';

const useProfilePanelStyles = makeStyles(() => {
    const classes = createStyles({
        icon: {
            borderRadius: '100%',
            width: 32,
            height: 32,
        },
        icon2: {
            borderRadius: '100%',
            width: 36,
            height: 36,
        },
        close: {
            position: 'absolute',
            right: 0,
            top: 0,
        },
        paper: {
            overflow: 'hidden',
        },
    });
    return classes;
});

const ProfilePanel = () => {
    const navigate = useNavigate();
    const classes = useProfilePanelStyles();
    const dispatch = useAppDispatch();
    const [anchorEle, setAnchorEle] = useState(null);
    const auth = useAppSelector((store) => store.auth);
    const { t } = useTranslation();

    const handleClickFaceIcon = (e) => {
        setAnchorEle(anchorEle ? null : e.currentTarget);
    };

    const handleClose = (event: MouseEvent<EventTarget>) => {
        setAnchorEle(null);
    };

    const handleSignout = () => {
        localStorage.removeItem('notRemindAgain');
        dispatch(logout());
    };

    return (
        <>
            <Button onClick={handleClickFaceIcon}>
                {auth?.profileUrl ? (
                    <img alt="profile" className={classes.icon} src={auth.profileUrl} />
                ) : (
                    <AccountCircleIcon />
                )}
            </Button>

            {auth && (
                <Popper open={Boolean(anchorEle)} anchorEl={anchorEle} transition disablePortal>
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Grow {...TransitionProps}>
                                <Paper className={classes.paper} elevation={5}>
                                    <Box p={2}>
                                        <IconButton className={classes.close} onClick={handleClose}>
                                            <ClearIcon />
                                        </IconButton>
                                        <Box pb={1}>
                                            <Typography align="center">{t('myAccount.account')}</Typography>
                                        </Box>
                                        <Box py={1}>
                                            <Divider></Divider>
                                        </Box>
                                        <ListItem>
                                            <ListItemIcon>
                                                {auth?.profileUrl ? (
                                                    <img
                                                        alt="profile"
                                                        className={classes.icon2}
                                                        src={auth.profileUrl}
                                                    />
                                                ) : (
                                                    <AccountCircleIcon fontSize="large" />
                                                )}
                                            </ListItemIcon>
                                            <Box>
                                                <Typography variant="body1">{auth?.username}</Typography>
                                                <Typography color="textSecondary" variant="body2">
                                                    {auth?.email}
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                        <ListItem button onClick={() => navigate('/my-account')}>
                                            <Typography>{t('avatar.personalSetting')}</Typography>
                                        </ListItem>
                                        <Box py={1}>
                                            <Divider></Divider>
                                        </Box>
                                        <ListItem button onClick={handleSignout}>
                                            {t('avatar.logout')}
                                        </ListItem>
                                    </Box>
                                </Paper>
                            </Grow>
                        </ClickAwayListener>
                    )}
                </Popper>
            )}
        </>
    );
};

export default ProfilePanel;
