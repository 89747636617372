import React from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { getFeaturesMap } from 'store';
import MainLayout from 'components/Layout/MainLayout';
import HeaderLayout from 'components/Header';
import loadable from '@loadable/component';

const Login = loadable(() => import('components/Pages/Login'));
const Showroom = loadable(() => import('components/Pages/Showroom'));
const SceneManage = loadable(() => import('components/Pages/SceneManage'));
const MyTemplate = loadable(() => import('components/Pages/MyTemplate'));
const MaterialManage = loadable(() => import('components/Pages/MaterialManage'));
const MyAccount = loadable(() => import('components/Pages/MyAccount'));
const CreateTemplatePage = loadable(() => import('components/Pages/Template/Create'));
const TemplatesPage = loadable(() => import('components/Pages/Template/Templates'));
const MyTemplatesPage = loadable(() => import('components/Pages/Template/MyTemplates'));
const MyTemplatesCategoryPage = loadable(() => import('components/Pages/Template/MyTemplatesCategory'));
const SceneManageInfoPage = loadable(() => import('components/Pages/SceneManage/Info'));
const SceneManageInfoSettingPage = loadable(() => import('components/Pages/SceneManage/Setting'));
const SceneManageInfoIntroVideoSettingPage = loadable(
    () => import('components/Pages/SceneManage/Setting/IntroVideo/IntroVideoSetting'),
);
const SceneManageInfobgMusicSettingPage = loadable(
    () => import('components/Pages/SceneManage/Setting/BackgroundMusic/BackgroundMusicSetting'),
);
const SceneManageInfoChatSettingPage = loadable(
    () => import('components/Pages/SceneManage/Setting/SocialChat/SocialChatSetting'),
);
const SceneManageInfoMeetingSettingPage = loadable(() => import('components/Pages/SceneManage/Setting/Meeting'));
const SceneManageInfoViewmodeSettingPage = loadable(() => import('components/Pages/SceneManage/Setting/ViewingMode'));
const SceneManageInfoVisionProSettingPage = loadable(() => import('components/Pages/SceneManage/Setting/VisionPro'));
const SceneManageMemberPage = loadable(() => import('components/Pages/SceneManage/Member'));
const SceneManageReportPage = loadable(() => import('components/Pages/SceneManage/Report'));
const SceneManagePilotPage = loadable(() => import('components/Pages/SceneManage/Pilot'));
const IframeCreateTemplatePage = loadable(() => import('components/Pages/Iframe/CreateTemplate'));
const IframeEditTemplateInfoPage = loadable(() => import('components/Pages/Iframe/EditTemplateInfo'));
const IframePreviewScenePage = loadable(() => import('components/Pages/Iframe/PreviewScene'));

const DealerCheck = () => {
    const { code } = useAppSelector((store) => store.dealer);
    const navigate = useNavigate();
    const location = useLocation();
    React.useEffect(() => {
        if (!code) return;
        if (!location.search.includes(`dealer=${code}`)) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('dealer', code);
            location.search = '?' + searchParams.toString();
            navigate(location, { replace: true });
        }
    }, [location, code]);
    return <Outlet />;
};

const AuthRoute = (props: { permission: boolean; Component?: React.ComponentType }) => {
    if (!props.permission) return null;
    if (props.Component) return <props.Component />;
    return <Outlet />;
};

export default function ({ isAuthorizing }: { isAuthorizing: boolean }) {
    const {
        template,
        ms,
        introVideo,
        pilot,
        meeting,
        report,
        member,
        backgroundMusic,
        textChat,
        audioChat,
        viewMode,
        tourRing,
    } = useAppSelector(getFeaturesMap);
    const auth = useAppSelector((store) => store.auth);

    return (
        <BrowserRouter>
            <Routes>
                <Route Component={DealerCheck}>
                    <Route path="iframe">
                        <Route path="create-template" Component={IframeCreateTemplatePage} />
                        <Route path="edit-info" Component={IframeEditTemplateInfoPage} />
                        <Route path="preview-scene" Component={IframePreviewScenePage} />
                    </Route>
                    <Route path="login" element={Boolean(auth) ? <Navigate to="/" /> : <Login />} />
                    <Route
                        element={
                            isAuthorizing ? (
                                <div>Loading...</div>
                            ) : Boolean(auth) ? (
                                <Outlet />
                            ) : (
                                <Navigate to="/login" />
                            )
                        }
                    >
                        <Route Component={HeaderLayout}>
                            <Route Component={MainLayout}>
                                <Route path="/" Component={Showroom} />
                                <Route path="material-manage" Component={MaterialManage} />
                                <Route path="template" element={<AuthRoute permission={template} />}>
                                    <Route path="create" Component={CreateTemplatePage} />
                                    <Route path="my-template" Component={MyTemplatesPage} />
                                    <Route path="my-template/:categoryId" Component={MyTemplatesCategoryPage} />
                                    <Route
                                        path="templates"
                                        element={<AuthRoute permission={ms} Component={TemplatesPage} />}
                                    />
                                </Route>
                            </Route>
                            <Route path="scene-manage/:sceneId" Component={SceneManage}>
                                <Route path="info" Component={SceneManageInfoPage} />
                                <Route path="settings" Component={SceneManageInfoSettingPage}>
                                    <Route
                                        path="intro-video"
                                        element={
                                            <AuthRoute
                                                permission={introVideo}
                                                Component={SceneManageInfoIntroVideoSettingPage}
                                            />
                                        }
                                    />
                                    <Route
                                        path="bg-music"
                                        element={
                                            <AuthRoute
                                                permission={backgroundMusic}
                                                Component={SceneManageInfobgMusicSettingPage}
                                            />
                                        }
                                    />
                                    <Route
                                        path="chatroom"
                                        element={
                                            <AuthRoute
                                                permission={textChat || audioChat || tourRing}
                                                Component={SceneManageInfoChatSettingPage}
                                            />
                                        }
                                    />
                                    <Route
                                        path="meeting"
                                        element={
                                            <AuthRoute
                                                permission={meeting}
                                                Component={SceneManageInfoMeetingSettingPage}
                                            />
                                        }
                                    />
                                    <Route
                                        path="viewingMode"
                                        element={
                                            <AuthRoute
                                                permission={true}
                                                Component={SceneManageInfoViewmodeSettingPage}
                                            />
                                        }
                                    />
                                    <Route
                                        path="visionPro"
                                        element={
                                            <AuthRoute
                                                permission={true}
                                                Component={SceneManageInfoVisionProSettingPage}
                                            />
                                        }
                                    />
                                </Route>
                                <Route
                                    path="members"
                                    element={<AuthRoute permission={member} Component={SceneManageMemberPage} />}
                                />
                                <Route
                                    path="reports"
                                    element={<AuthRoute permission={report} Component={SceneManageReportPage} />}
                                />
                                <Route
                                    path="pilot"
                                    element={<AuthRoute permission={pilot} Component={SceneManagePilotPage} />}
                                />
                            </Route>
                            <Route
                                path="my-template"
                                element={<AuthRoute permission={template} Component={MyTemplate} />}
                            />
                            <Route path="my-account" Component={MyAccount} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
