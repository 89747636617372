import { platformApi, setPlatformHeader } from 'api';
import jsCookie from 'js-cookie';
import { platformTokenKey } from 'constants/api';
import { Payment } from 'types/creditCard';

export const getCreditCardInfo = async () => {
    // sso
    const urlEnd = '/api/v1/card';
    const response = await platformApi.get(urlEnd);
    const result = response.data;
    return result;
};

export const putCardNumbers = async (stripeToken: string) => {
    // sso
    const config = {
        headers: {
            StripeToken: stripeToken,
        },
    };
    const urlEnd = '/api/v1/card';
    const response = await platformApi.put(urlEnd, {}, config);
    const result = response.data;
    return result;
};

export const getPayment = async () => {
    // sso
    const urlEnd = '/api/v1/payment/order';
    const response = await platformApi.get<Payment>(urlEnd);
    const result = response.data;
    return result;
};

export const postUnsubscribe = async (packageId: string) => {
    const urlEnd = `/api/v1/payment/subscription/${packageId}`;
    const platformToken = jsCookie.get(platformTokenKey);
    setPlatformHeader('Authorization', `Bearer ${platformToken}`);
    const response = await platformApi.delete(urlEnd);
    const result = response.data;
    return result;
};

export const postSubscription = async (packageId: string, stripeToken: string, fromType = 'online') => {
    const urlEnd = '/api/v1/payment/subscription';
    const platformToken = jsCookie.get(platformTokenKey);
    const body = {
        packageId,
        stripeToken,
        fromType,
    };
    setPlatformHeader('Authorization', `Bearer ${platformToken}`);
    const response = await platformApi.post(urlEnd, body);
    const result = response.data;
    return result;
};
