export const platformTokenKey = 'platform-token';
export const platformUserIdKey = 'platform-user-id';
export const editorUserIdKey = 'user-id';

const PRODUCT_IDS: {
    [key: string]: {
        [key: string]: string;
    };
} = {
    development: {
        metaMaker: '402886ee7b2de269017b2de3a22e0000',
    },
    test: {
        metaMaker: '402886ee7b2de269017b2de3a22e0000', // dev
    },
    production: {
        metaMaker: '402886f27c112667017c1127e3910000',
    },
};

export const productIds = PRODUCT_IDS[process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV || 'development'];
