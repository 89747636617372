import * as dealerApi from 'api/dealer';
import { DealerInfo } from 'types/dealer';
import SERVER from 'constants/server';
import { Dispatch } from 'redux';

export const ACTION_TYPES = {
    setDealerInfo: 'setDealerInfo',
} as const;

export const createSetDealerInfoAction = (info: DealerInfo) => ({
    type: ACTION_TYPES.setDealerInfo,
    payload: info,
});

export const getDealerInfo =
    (code: string) =>
    async (dispatch): Promise<DealerInfo> => {
        const dealerInfo = await dealerApi.getDealerInfo(code);
        SERVER.VIEWER_URL = dealerInfo.openLink || SERVER.VIEWER_URL;
        dispatch(
            createSetDealerInfoAction({
                isDealer: true,
                code: code,
                brandLogo: dealerInfo.brandLogo,
                productLogo: dealerInfo.productLogo,
                productName: dealerInfo.productName || '',
                title: dealerInfo.title || '',
                favicon: dealerInfo.favicon,
                mainColor: dealerInfo.mainColor,
                subColor: dealerInfo.subColor,
                homePage: dealerInfo.homePage,
                openLink: dealerInfo.openLink,
                loginBg: dealerInfo.loginBg,
                colorMode: dealerInfo.colorMode,
            }),
        );
        return dealerInfo;
    };

export const setDealerAsIstaging = () => {
    return createSetDealerInfoAction({
        isDealer: false,
        code: '',
        title: 'iStaging METAmakeR',
        favicon: '/favicon.ico',
        brandLogo: '/images/istaging-logo.png',
        productLogo: '/images/metamaker-logo-white.png',
        productName: 'METAmakeR',
        mainColor: '#F2539D',
        subColor: '#662D91',
        homePage: '',
        openLink: '',
        loginBg: '/images/login-bg.png',
        colorMode: 'light',
    });
};
