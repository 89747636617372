// react, redux
import React, { useState, useRef, MouseEvent } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import clx from 'classnames';

// material ui, css
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import HomeIcon from '@material-ui/icons/Home';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MenuIcon from '@material-ui/icons/Menu';

// components, hooks
import HideOnScroll from 'components/Utils/HideOnScroll';
import { useAppSelector } from 'hooks/store';

// resource
import LogoIcon from 'images/icon/logo.png';
import VrmakerLogoIcon from 'images/icon/vrmaker-logo.png';

import { useTranslation } from 'react-i18next';
import PackagePanel from './PackagePanel';

const officialWebsite = {
    en: 'https://www.istaging.com/en/',
    tw: 'https://www.istaging.com/zh-tw/',
};

const useStyles = makeStyles((theme) => {
    const classes = createStyles({
        root: {
            transition: 'background-color 1s',
        },
        toolbar: {
            maxHeight: '46px',
            minHeight: '46px',
        },
        productTitle: {
            marginLeft: 10,
            fontWeight: 'bold',
        },
        paper: {
            overflow: 'hidden',
        },
        icon: {
            width: 32,
            height: 32,
            marginRight: 15,
            '& img': {
                width: '100%',
            },
        },
        align: {
            verticalAlign: 'top',
        },
        buttonColor: {
            color: 'inherit',
        },
    });

    return classes;
});

const ProductLink = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <List>
            <ListItem>
                <Typography variant="body2">{t('common.redirect')}</Typography>
            </ListItem>

            <ListItem>
                <div className={classes.icon}>
                    <img src={LogoIcon} alt="metamaker" />
                </div>
                <ListItemText>METAmakeR</ListItemText>
            </ListItem>

            <ListItem
                button
                component="a"
                href="https://vreditor.istaging.com/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <div className={classes.icon}>
                    <img src={VrmakerLogoIcon} alt="vrmaker" />
                </div>
                <Typography variant="body2">VR maker</Typography>
            </ListItem>
        </List>
    );
};

const MenuPanel = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const auth = useAppSelector((store) => store.auth);
    const { isDealer } = useAppSelector((store) => store.dealer);
    const [anchorEle, setAnchorEle] = useState(null);
    const { t, i18n } = useTranslation();
    const isEnUS = i18n.language === 'en-US';

    const handleClose = () => {
        setAnchorEle(null);
    };

    const handleClickMenuIcon = (e) => {
        setAnchorEle(anchorEle ? null : e.currentTarget);
    };

    return (
        <>
            {!isDealer && (
                <IconButton className={classes.buttonColor} onClick={handleClickMenuIcon}>
                    <MenuIcon />
                </IconButton>
            )}
            <IconButton className={classes.buttonColor} onClick={() => navigate('/')}>
                <HomeIcon />
            </IconButton>

            {auth && (
                <Popper open={Boolean(anchorEle)} anchorEl={anchorEle} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper className={classes.paper} elevation={5}>
                                    <Box px={2}>
                                        <Box position="absolute" top={6} right={0}>
                                            <IconButton onClick={handleClose}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                        <Box my={2} px={6}>
                                            <Typography align="center">{t('common.moreProducts')}</Typography>
                                        </Box>
                                        <Divider />
                                        <ProductLink />
                                        <Divider />
                                        <Box my={2}>
                                            <Link
                                                href={isEnUS ? officialWebsite.en : officialWebsite.tw}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span className={classes.align}>iStaging &nbsp;</span>
                                                <OpenInNewIcon fontSize="small" />
                                            </Link>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grow>
                        </ClickAwayListener>
                    )}
                </Popper>
            )}
        </>
    );
};

const Header = () => {
    const classes = useStyles();
    const { productName, productLogo } = useAppSelector((store) => store.dealer);

    return (
        <>
            <HideOnScroll>
                <AppBar className={classes.root}>
                    <Toolbar className={classes.toolbar}>
                        <MenuPanel />
                        <Box display="flex" alignItems="center" mx="auto">
                            {productLogo && <img src={productLogo} alt="product logo" />}
                            <Typography variant="h6" className={classes.productTitle}>
                                {productName}
                            </Typography>
                        </Box>
                        <PackagePanel />
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Outlet />
        </>
    );
};

export default Header;
