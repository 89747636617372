import { platformApi } from 'api';

export const postSubscribePackage = async (packageId: string, stripeToken?: string) => {
    // sso
    const urlEnd = '/api/v1/payment/change';
    const body = {
        packageId,
        stripeToken,
    };
    const response = await platformApi.post(urlEnd, body);
    const result = response.data;
    return result;
};
