import { AnyAction } from 'redux';

import { ACTION_TYPES } from 'actions/scene';
import { Scene } from 'types/scene';
import { withDefault, getSceneModel } from 'api/models';

interface Action extends AnyAction {
    type: keyof typeof ACTION_TYPES;
}

const initialState = withDefault(getSceneModel)();

const sceneReducer = (state: Scene = initialState, action: Action): Scene => {
    switch (action.type) {
        case ACTION_TYPES.setSceneInfo:
            return { ...action.payload };
        default:
            return state;
    }
};

export default sceneReducer;
