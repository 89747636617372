import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import PLANS from 'constants/plans';
import { useAppSelector } from 'hooks/store';
import { getPackageInfo } from 'store';
import styles from './index.module.css';
import PlanCard from '../PlanCard';

const ChoosePlanModal = ({ onClose, onNext }: { onClose: () => void; onNext: (packageId: string) => void }) => {
    const { t } = useTranslation();
    const packageInfo = useAppSelector(getPackageInfo);

    return (
        <Modal open className={styles['choose-plan-modal']}>
            <div className={styles['choose-plan-modal-wrapper']}>
                <button onClick={onClose} type="button" className={styles['close-button']}>
                    <CloseIcon fontSize="large" />
                </button>
                <h1 className={styles['choose-plan-modal-title']}>{t('upgrade.choosePlan')}</h1>
                <p>{t('upgrade.choosePlanDescription')}</p>
                <div className={styles['plan-wrapper']}>
                    {PLANS.map((plan) => (
                        <PlanCard
                            key={plan.packageId}
                            plan={plan}
                            packageId={packageInfo.id || ''}
                            onClick={() => onNext(plan.packageId)}
                        />
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default ChoosePlanModal;
